`
<template>
  <PageContainer :title="$translations.referrals.title" :back="back">
    <template v-slot:content>
      <template v-if="loading">
        <Skeleton />
      </template>

      <template v-else>
        <!-- how works section -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div class="referral-banner">
              <HtmlViewer
                class="heading-display text-white"
                :html-content="banner"
              />
              <img
                src="https://s3.amazonaws.com/images.app.selia/gift.png"
                alt="gift"
              />
            </div>
            <Heading class="margin-bottom" :text="title" />
            <div
              class="referral-step"
              v-for="(item, index) of referralsContent.steps"
              :key="index"
            >
              <div class="referral-step-icon" />
              <div class="referral-step-content">
                <div
                  class="referral-step-title content-title"
                  v-text="titleFormat(item.title[lang])"
                />
                <div
                  class="referral-step-description body-small"
                  v-text="item.description[lang]"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- share section -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="content-title margin-bottom"
              v-text="$translations.referrals.share"
            />

            <div
              class="referral-copy section-container-white margin-bottom"
              @click="copy"
            >
              <div
                class="heading-display text-gray-80"
                v-text="referralsData.code"
              />
              <div class="icon-copy-off icon-display text-gray-50" />
            </div>

            <div class="referral-actions">
              <Button
                class="whatsapp-button"
                :text="$translations.general.whatsapp"
                icon="icon-whatsapp"
                color="green"
                :iconLeft="true"
                @clicked="goWhatsapp"
              />
              <Button
                class="more-options-button"
                :text="$translations.general['more-options']"
                icon="icon-share-on"
                type="outline"
                :iconLeft="true"
                @clicked="goMoreOptions"
              />
            </div>
          </div>
        </div>

        <!-- you referrals section -->
        <div class="page-segment margin-bottom">
          <div class="page-segment-content">
            <div
              class="content-title margin-bottom"
              v-text="$translations.referrals['you-referrals']"
            />

            <div class="referral-data">
              <div class="referral-data-item">
                <div
                  class="heading-medium text-purple"
                  v-text="referralsData.total_redemption_count"
                />
                <div class="referral-data-item-footer">
                  <div
                    class="text-gray-40 icon-person-on referral-data-item-footer-icon"
                  />
                  <div
                    class="body-small"
                    v-text="$translations.referrals.friends"
                  />
                </div>
              </div>

              <div class="referral-data-item">
                <div
                  class="heading-medium text-purple"
                  v-text="currencyFormat"
                />
                <div class="referral-data-item-footer">
                  <div
                    class="text-gray-40 icon-money-on referral-data-item-footer-icon"
                  />
                  <div
                    class="body-small text-gray-60"
                    v-text="$translations.referrals.credit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </PageContainer>
</template>

<script>
import {
  Settings,
  PageContainer,
  Referrals,
  Skeleton,
  Heading,
  HtmlViewer,
  Button,
  ResponsiveMixin
} from '@seliaco/red-panda'
import { Share } from '@capacitor/share'
import { ReferralEvent } from '@/types/events'

export default {
  name: 'Referrals',
  components: { PageContainer, Skeleton, Heading, HtmlViewer, Button },
  mixins: [ResponsiveMixin],
  data () {
    return {
      lang: this.$store.getters.language.lang,
      priceCountry: '',
      referralsContent: null,
      referralsData: null,
      loading: true,
      back: {
        callback: () => this.$router.push({ name: 'Home' })
      }
    }
  },
  created () {
    ReferralEvent.ReferralViewed()
    this.getContent()
  },
  methods: {
    async getContent () {
      const promises = [Settings.get('REFERRAL_PAGE_CONTENT'), Referrals.get()]

      const [content, data] = await Promise.all(promises).catch((error) => {
        console.error(error)
      })

      this.referralsContent = content.parse_value
      this.referralsData = data

      this.priceCountry =
        this.referralsContent.country[
          this.$store.getters['auth/user'].country
        ] || this.referralsContent.country['US']

      this.loading = false
    },
    goWhatsapp () {
      ReferralEvent.ReferralShare('whatsapp')

      const message = this.safeEncodeURI(
        this.referralsContent['share'][this.lang]
          .replace('{referral_code}', this.referralsData.code)
          .replace('{url}', this.referralsContent['sign-up-url'])
      )

      window.open(`https://wa.me/?text=${message}`)
    },
    async goMoreOptions () {
      ReferralEvent.ReferralShare('more_options')

      const message = this.referralsContent['share'][this.lang]
        .replace('{referral_code}', this.referralsData.code)
        .replace('{url}', this.referralsContent['sign-up-url'])

      if (this.isMobile) {
        try {
          await Share.share({
            text: message
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        const data = {
          url: this.referralsContent['sign-up-url'],
          title: this.safeEncodeURI(message)
        }

        this.$globalEvent.$emit('modal/share-dialog', {
          showDialog: { shareDialog: { open: true, close: false, data } }
        })
      }
    },
    safeEncodeURI (str) {
      return Array.from(str)
        .map(function (char) {
          return encodeURIComponent(char)
        })
        .join('')
    },
    copy () {
      ReferralEvent.ReferralShare()
      navigator.clipboard.writeText(this.referralsData.code)
      this.$toast({
        text: this.$translations.general.copy,
        severity: 'success'
      })
    }
  },
  computed: {
    banner () {
      return this.referralsContent?.banner[this.lang].replace(
        '{price}',
        `<b>${this.priceCountry}</b>`
      )
    },
    title () {
      return this.referralsContent['title-step'][this.lang]
    },
    titleFormat () {
      return (title) => {
        if (title.includes('{price}')) {
          return title.replace('{price}', this.priceCountry)
        }

        return title
      }
    },
    currencyFormat () {
      return `$ ${this.referralsData.total_redemption_value / 100}`
    }
  }
}
</script>

<style lang="sass" scoped>
.referral-banner
  border-radius: 16px
  display: flex
  align-content: center
  padding: 24px
  background: linear-gradient(90deg, #8350E6 0.87%, #4C14A5 98.69%)
  margin-bottom: 20px
  overflow: hidden
  position: relative
  .heading-display
    max-width: 240px

  img
    position: absolute
    width: 141px
    bottom: 0
    right: 0

.referral-step
  display: grid
  grid-template-columns: 8px 1fr
  gap: 16px
  padding-bottom: 32px
  position: relative

  &:last-child
    .referral-step-icon
      &::after
        display: none

.referral-step-icon
  margin-top: 6px
  width: 8px
  height: 8px
  background-color: var(--purple)
  border-radius: 50%

  &::after
    content: ''
    position: absolute
    display: block
    width: 2px
    height: -webkit-fill-available
    background-color: var(--gray-10)
    top: 14px
    left: 3px


.referral-step-content
  display: flex
  flex-direction: column
  gap: 8px

.referral-copy
  display: flex
  align-items: center
  justify-content: center
  gap: 8px
  &:active
    border: 2px solid var(--purple-50)
    box-shadow: 0px 4px 16px 0px rgba(131, 80, 230, 0.20)
    .icon-display
      color: var(--purple) !important
    .heading-display
      color: var(--purple) !important

.referral-actions
  display: flex
  gap: 16px
  margin-top: 16px
  padding-bottom: 24px
  border-bottom: 1px solid var(--gray-10)
  .more-options-button
    ::v-deep
      .text
        text-wrap: nowrap

.whatsapp-button
  background: #81D479

.referral-data
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 18px


  &-item
    display: flex
    padding: 16px
    flex-direction: column
    align-items: center
    gap: 4px
    border-radius: 16px
    background: var(--gray-5)

    &-footer
      display: flex
      justify-content: center
      align-items: center
      gap: 4px
      align-self: stretch

      &-icon
        font-size: 16px
        width: 16px
        height: 16px

@media (max-width: 840px)
  .referral-banner
    .heading-display
      max-width: 224px
</style>
